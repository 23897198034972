<template>
  <div>
    <div class="wrapper">
      <nav id="sidebar">
        <sidebar @closeMenu="closeMenu" />
      </nav>
      <section id="content" class="d-flex flex-column pl-2">
        <navbar @showMenu="showMenu" />
        <router-view class="pr-4" />
        <footer
          id="footer"
          class="text-muted text-right px-4 py-2 pb-2 flex-grow-0"
        >
          <!--<small>Pague App - </small>-->
        </footer>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar.vue";
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Sidebar,
    Navbar,
  },
  methods: {
    showMenu() {
      document.getElementById("sidebar").style.width = "250px";
      console.log("show");
    },
    closeMenu(){
      document.getElementById("sidebar").style.width = "0px";
      console.log("show");
    },
    redirectTo(){
      const routes = this.$store.state.auth.userPermissions
      _.forEach(routes, (route)=>{
      if(route.path){
        this.$router.push(route.path)
      }
      return false
    })
    }
  },
  mounted(){
    const routes = this.$store.state.auth.userPermissions
    let found = false    
    const currentRoute = this.$route
    _.forEach(routes, (route)=>{
      if(route.path == currentRoute.path){
        found = true
      }      
    })
    if(!found){
      this.redirectTo()
    }
  } 
};
</script>


<style lang="scss">
.wrapper {
  overflow-x: hidden;
  display: flex;
  width: 100%;
  align-items: stretch;
  background-color: #efeeea !important;
}
#sidebar {
  min-width: 250px;
  max-width: 250px;  
  transition: all 0.3s;
  background: #efeeea !important;
  z-index: 0;
}
#content {
  width: 100%;
  background-color: #efeeea !important;
  min-height: 100vh;
  transition: all 0.3s;
  z-index: 0;
}
@media (max-width: 1024px) {
  #sidebar {
    width: 0;
    overflow: hidden;
    position: absolute;
    background-color: white;
    z-index: 10;
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.list::-webkit-scrollbar {
  -webkit-appearance: scrollbarthumb-vertical;
}
.list::-webkit-scrollbar {
  -webkit-appearance: scrollbartrack-vertical;
}


// #app-grid {
// 	overflow-x: hidden;
// 	display: -moz-grid;
// 	display: -ms-grid;
// 	display: grid;
// 	min-height: 100vh;
// 	grid-template:
// 		'navigation content' 1fr
// 		'navigation footer' auto
// 		// "navigation header" $header-height * 2
// 		/ auto 0.95fr;

// 	// #header { grid-area: header; }
// 	#navigation {
// 		grid-area: navigation;
// 	}
// 	#footer {
// 		grid-area: footer;
// 	}
// 	#content {
// 		grid-area: content;
// 	}
// 	#header-bg {
// 		border-bottom-left-radius: $header-bg-radius;
// 	}
// }
</style>