import { _ } from 'core-js'
import Http from 'Http'

const state = {
    leads: []
}
const getters = {

}
const actions = {
    async leads_init({ dispatch, commit }) {
        const [leads] = await Promise.all([
            dispatch('get_leads')
        ]).catch(error => {
            this.state.$app.toastError('leads/lead_init', error)
        })

        console.log(leads)
        commit('SET', { state: 'leads', data: leads })

    },
    async get_leads({ }) {

        let data = []

        try {

            const response = await Http().get('/lead')

            _.forEach(response.data, (lead) => {

                const parse = JSON.parse(lead.file)

                lead.file = parse
            })

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_filtered_leads({ commit }, filter) {

        let URI = ''

        const keys = Object.keys(filter)

        _.forEach(keys, (key) => {

            if (filter[key] !== 'all') {

                URI = URI + `${key}=${filter[key]}`

            }
        })

        URI = encodeURI(
            `?${URI}`
        );

        let data = []

        try {

            const response = await Http().get(`/lead${URI}`)            

            _.forEach(response.data, (lead) => {

                const parse = JSON.parse(lead.file)

                lead.file = parse
            })            

            data = response.data
            
            commit('SET', {state:'leads', data})

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async save_confirm_lead({ }, model) {

        try {

            const response = await Http().put(`/lead`, model)

            console.log(response)

            if (response.status == 201 || response.status == 200) {

                this.state.$app.toastSuccess('Lead confirmado com sucesso')               

            }

        } catch ({ response }) {

            this.state.$app.toastError('leads/save_confirm_lead', response)

        }

        return true
    },
}
const mutations = {
    ['SET']: (state, data) => {
        state[data.state] = data.data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}