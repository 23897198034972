export default ({bankList})=>[
    [
      {
        label: "Banco",
        col_size: "8",
        model: "bank_id",
        type: "select",
        selectOptions: bankList,
        required: true,
      },
      {
        label: "Agencia",
        col_size: "4",
        model: "agency",
        required: true,
      },
    ],
    [
      {
        label: "Tipo de Conta",
        model: "account_type",
        type: "select",
        required: true,
        selectOptions: [
          {
            value: "1",
            text: "C/C",
          },
          {
            value: "2",
            text: "Poupança",
          },
        ],
      },
      {
        label: "Conta",
        col_size: "4",
        model: "account",
        required: true,
      },
    ],
    [
      {
        label: "Descrição",
        model: "description",
        required: true,
      },
      {
        label: "Documento(CPF/CNPJ)",
        model: "document",
        required: true,
      },
    ],
  ]