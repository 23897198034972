<template>
  <b-col :md="schema.col_size">
    <label>{{ schema.label }}</label>
    <template
      v-if="
        schema.type == undefined ||
        schema.type == 'number' ||
        schema.type == 'date'
      "
    >
      <b-input
        :class="[hasError ? 'border border-danger' : false]"
        :type="schema.type"
        :value="value"
        :disabled="schema.disabled || false"
        @input="$emit('input', $event)"
      />
    </template>
    <template v-if="schema.type == 'select'">
      <b-form-select
        :options="schema.selectOptions"
        :class="[hasError ? 'border border-danger' : false]"
        :disabled="schema.disabled || false"
        :value="value"
        @change="$emit('input', $event)"
      >
      </b-form-select>
    </template>
    <span v-if="hasError" :class="[hasError ? 'text-danger' : false]">{{
      schema.errorMsg || "Campo obrigatório"
    }}</span>
  </b-col>
</template>

<script>
export default {
  props: ["schema", "value", "errors"],
  computed: {
    hasError() {
      if (this.errors) {
        if (this.errors.includes(this.schema.model)) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style>
</style>