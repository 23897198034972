import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookie from 'js-cookie'
import store from '@/store'
import Index from '@/views/Index.vue'

const childrenRoutes = [];

const requireModules = require.context('@/views', true, /\.js$/);

requireModules.keys().forEach(fileName => {

  const folders = fileName.split('/')  

  if(folders[folders.length - 1] == 'index.js' && folders[folders.length - 2] == 'router'){    
    
    childrenRoutes.push(...requireModules(fileName).default)

  }  
  
});

Vue.use(VueRouter)

const routes = [
  {
    path: '',       
    component: Index,    
    children:childrenRoutes
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/check/:access_token',
    name: 'redir',
    component: Index
  },
  {
    path: '*',
    redirect: { name: 'leads' }
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {  

  console.log(to)

  if (to.path == '/') {

    router.push(process.env.VUE_APP_INDEX_PAGE)

  }

  if (to.name == 'login') {

    document.title = `${process.env.VUE_APP_APPLICATION_NAME || 'SETAR VARIAVEL APPLICATION_NAME'} | Login`

    next()

  } else {
    if (to.params.access_token) {

      const refresh = to.params.access_token

      const application_id = process.env.VUE_APP_APPLICATION_ID

      const response = await store.dispatch('auth/cross_domain', { refresh, application_id })

      if (response) {

        router.push(process.env.VUE_APP_INDEX_PAGE)

      } else {

        router.push('/login')

      }

    } else if (Cookie.get('refresh_token')) {

      document.title = `${process.env.VUE_APP_APPLICATION_NAME || 'SETAR VARIAVEL APPLICATION_NAME'} | ${to.meta.title}`

      await store.dispatch('auth/init')

      next()

    } else {

      router.push('login')

    }
  }
})

export default router

