export default
    [
        //List
        {
            path: '/empresas',
            name: 'unities',
            meta: {
                icon: 'file-code',
                title: 'Empresas'
            },
            component: () => import('@/views/unities/list/Index')
        },
        {
            path: '/aplicacoes_produtos/:unityId',
            name: 'applications_products.unityId',
            meta: {
                icon: 'file-code',
                title: 'Aplicações/Produtos'
            },
            component: () => import('@/views/unities/applications_products/Index')
        },
        //bank_account
        {
            path: '/contas_bancarias/:unityId',
            name: 'bank_accounts.unityId',
            meta: {
                icon: 'file-code',
                title: 'Contas Bancárias'
            },
            component: () => import('@/views/unities/bank_accounts/Index')
        },
        {
            path: '/contas_bancarias/:unityId/adicionar',
            name: 'bank_accounts.unityId.add',
            meta: {
                icon: 'file-code',
                title: 'Contas Bancárias'
            },
            component: () => import('@/views/unities/bank_accounts/AddView')
        },
        {
            path: '/contas_bancarias/:unityId/editar/:accountId',
            name: 'bank_accounts.unityId.edit',
            meta: {
                icon: 'file-code',
                title: 'Contas Bancárias'
            },
            component: () => import('@/views/unities/bank_accounts/EditView')
        },
        //communication_rate
        {
            path: '/taxas_de_envio/:unityId',
            name: 'communication_rate.unityId',
            meta: {
                icon: 'file-code',
                title: 'Taxas de Envio'
            },
            component: () => import('@/views/unities/communication_rate/Index')
        },
        //payment_rate
        {
            path: '/taxas_de_tipo/:unityId',
            name: 'payment_rate.unityId',
            meta: {
                icon: 'file-code',
                title: 'Taxas de Envio'
            },
            component: () => import('@/views/unities/payment_rate/Index')
        },
        //transfer_rate
        {
            path: '/taxas_de_transferencia/:unityId',
            name: 'transfer_rate.unityId',
            meta: {
                icon: 'file-code',
                title: 'Taxas de Transferência'
            },
            component: () => import('@/views/unities/transfer_rate/Index')
        },
    
    ]