export default
    [
        //List
        {
            path: '/leads',
            name: 'leads',
            meta: {
                icon: 'file-code',
                title: 'Leads'
            },
            component: () => import('@/views/leads/list/Index')
        },
        
    
    ]