import Http from 'Http'

const state = {
    unities: [],
    bankAccounts: [],
    applicationsProducts: [],
    bankList: [],
    communicationRateList: [],
    paymentRateList: [],
    transferRateList: [],
}
const getters = {

}
const actions = {
    async unities_init({ dispatch, commit }) {
        const [unities] = await Promise.all([

            dispatch('get_unities')

        ]).catch(error => {

            this.state.$app.toastError('unities/unities_init', error)

        })

        commit('SET', { state: 'unities', data: unities })

    },
    async applications_products_init({ dispatch, commit }, unityId) {

        const [applicationsProducts] = await Promise.all([

            dispatch('get_applications_products', unityId)

        ]).catch(error => {

            this.state.$app.toastError('unities/applications_products_init', error)

        })

        commit('SET', { state: 'applicationsProducts', data: applicationsProducts })

    },
    async bank_accounts_init({ dispatch, commit }, unityId) {

        const [bankAccounts, bankList] = await Promise.all([

            dispatch('get_bank_accounts', unityId),
            dispatch('get_bank_list')

        ]).catch(error => {

            this.state.$app.toastError('unities/bank_accounts_init', error)

        })

        console.log(bankAccounts)

        commit('SET', { state: 'bankAccounts', data: bankAccounts })
        commit('SET', { state: 'bankList', data: bankList })

    },
    async communication_rate_init({ dispatch, commit }, unityId) {

        const [communicationRateList] = await Promise.all([

            dispatch('get_communication_rate', unityId)

        ]).catch(error => {

            this.state.$app.toastError('unities/bank_accounts_init', error)

        })

        commit('SET', { state: 'communicationRateList', data: communicationRateList })

    },
    async payment_rate_init({ dispatch, commit }, unityId) {

        const [paymentRateList] = await Promise.all([

            dispatch('get_payment_rate', unityId)

        ]).catch(error => {

            this.state.$app.toastError('unities/payment_rate_init', error)

        })

        commit('SET', { state: 'paymentRateList', data: paymentRateList })

    },
    async transfer_rate_init({ dispatch, commit }, unityId) {

        const [transferRateList] = await Promise.all([

            dispatch('get_transfer_rate', unityId)

        ]).catch(error => {

            this.state.$app.toastError('unities/transfer_rate_init', error)

        })

        commit('SET', { state: 'transferRateList', data: transferRateList })

    },
    async bank_accounts_add_edit_init({ dispatch, commit }) {

        const [bankList] = await Promise.all([

            dispatch('get_bank_list')

        ]).catch(error => {

            this.state.$app.toastError('unities/bank_accounts_init', error)

        })

        commit('SET', { state: 'bankList', data: bankList })

    },
    async get_unities({ }) {

        let data = []

        try {

            const response = await Http().get('/unity')

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_filtered_unities({ commit }, filter) {

        let URI = ''

        const keys = Object.keys(filter)

        _.forEach(keys, (key, index) => {

            if (index == 0) {

                URI = URI + `${key}=${filter[key]}`

            } else {

                URI = URI + `&${key}=${filter[key]}`

            }

        })

        URI = encodeURI(

            `?${URI}`

        );        

        let data = []

        try {

            const response = await Http().get(`/unity${URI}`)            

            data = response.data            

            commit('SET', { state: 'unities', data })

        } catch ({response}) {            

            this.state.$app.toastError('unities/get_filtered_unities', response)

        }

        return data
    },
    async get_applications_products({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/unity/${unityId}/application`)

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_bank_accounts({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/unity/${unityId}/bank-account`)

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_bank_account_by_id({ }, accountId) {        

        let data = []

        try {

            const response = await Http().get(`/unity-bank-account/${accountId}`)

            data = response.data

        } catch ({ response }) {

            this.state.$app.toastError('unities/get_bank_account_by_id', response)

        }

        return data[0]
    },
    async get_bank_list({ }) {

        let data = []

        try {

            const response = await Http().get(`/bank`)

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_communication_rate({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/unity/${unityId}/communication-rate`)

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_payment_rate({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/unity/${unityId}/payment-rate`)

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_transfer_rate({ }, unityId) {

        let data = []

        try {

            const response = await Http().get(`/unity/${unityId}/transfer-rate`)

            data = response.data

        } catch ({ response }) {

            throw response

        }

        return data
    },
    async get_unity_details({ }, id) {

        let data = []

        try {

            const response = await Http().get(`/unity/${id}`)

            data = response.data

        } catch ({ response }) {

            this.state.$app.toastError('unities/get_unity_details', response)

            return false

        }

        return data
    },
    async save_bank_account({ commit }, model) {

        try {

            const response = await Http().post(`/unity-bank-account`, model)

            if (response.status == 201) {

                this.state.$app.toastSuccess('Nova conta criada com sucesso')

                commit('PUSH', { state: 'bankAccounts', data: response.data })

                return true

            }

        } catch ({ response }) {

            this.state.$app.toastError('unities/save_bank_account', response)

        }
    },
    async update_bank_account({ commit }, model) {

        console.log(model)

        try {

            const response = await Http().put(`/unity-bank-account`, model)

            if (response.status == 201 || response.status == 200) {

                this.state.$app.toastSuccess('Conta alterada com sucesso')                

                return true

            }

        } catch ({ response }) {

            this.state.$app.toastError('unities/save_bank_account', response)

        }
    }
}
const mutations = {
    ['SET']: (state, data) => {
        _.set(state, data.state, data.data)

    },
    ['PUSH']: (state, data) => {
        state[data.state].push(data.data)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}