export default [
    [

        {
            label: "Empresa",
            col_size: "6",
            model: "commercial_name",
            disabled: true
        },
        {
            label: "CNPJ",
            col_size: "6",
            model: "cnpj",
            disabled: true
        }
    ],
    [
        {
            label: "Razão Social",            
            model: "company_name",
            disabled: true
        },
        {
            label: "Unidade",            
            model: "commercial_name_unity",
            disabled: true
        },
    ],
    [
        {
            label: "Contato",
            col_size: "4",
            model: "contact_name",
            disabled: true
        },
        {
            label: "Celular do contato",
            model: "cell_phone",
            disabled: true 
        },
        {
            label: "Email do contato",
            model: "email",
            disabled: true 
        },
    ],
    [
        {
            label: "Rua",
            model: "street",
            disabled: true, 
            col_size:'4'
        },
        {
            label: "Numero",
            model: "number",
            disabled: true, 
            col_size:'2'
        },
        {
            label: "Bairro",
            model: "district",
            disabled: true, 
            col_size:'3'
        },
        {
            label: "Complemento",
            model: "complement",
            disabled: true, 
            col_size:'3'
        },
    ],
    [
        {
            label: "Estado",
            model: "state",
            disabled: true, 
            
        },
        {
            label: "Cidade",
            model: "city",
            disabled: true, 
           
        },
        
    ],
]