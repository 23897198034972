import Vue from 'vue'
import Moment from 'moment'

const mixin = {
    data() {
        return {
            toPortuguese: {
                name: "Nome",
                cpf: "CPF",
                rg: "RG",
                birthday: "Aniversário",
                nationality: "Nacionalidade",
                email: "Email",
                cell_phone: "Celular",
                home_phone: "Telefone Residencial",
                business_phone: 'Telefone Comercial',
                zip_code: 'CEP',
                address: "Endereço",
                number: "Numero",
                complement: 'Complemento',
                district: 'Bairro',
                state: 'Estado',
                city: 'Cidade'
            }
        }
    },
    filters:{

        maskValue: function(val){

            const config = { style: "currency", currency: "BRL" };

            const numberFormat1 = new Intl.NumberFormat("pt-BR", config);

            return numberFormat1.format(val);
        }

    },
    methods: {
        validaCpf(c) {
            if ((c = c.replace(/[^\d]/g, '')).length != 11) return false

            if (
                c == '00000000000' ||
                c == '11111111111' ||
                c == '22222222222' ||
                c == '33333333333' ||
                c == '44444444444' ||
                c == '55555555555' ||
                c == '66666666666' ||
                c == '77777777777' ||
                c == '88888888888' ||
                c == '99999999999'
            )
                return false

            var r
            var s = 0

            for (let i = 1; i <= 9; i++) s = s + parseInt(c[i - 1]) * (11 - i)

            r = (s * 10) % 11

            if (r == 10 || r == 11) r = 0

            if (r != parseInt(c[9])) return false

            s = 0

            for (let i = 1; i <= 10; i++) s = s + parseInt(c[i - 1]) * (12 - i)

            r = (s * 10) % 11

            if (r == 10 || r == 11) r = 0

            if (r != parseInt(c[10])) return false

            return true
        },  
        
        makeToast({ title, msg, variant }) {
            this.$bvToast.toast(msg, {
                title,
                variant

            })
        },             
        toastError(storeAction, responseError){            
            const msg = `O servidor retornou status ${responseError.status}, com a seguinte mensagem ${responseError.statusText}`
            const title = `Opz! Algo deu errado em '${storeAction}'`
            this.$bvToast.toast(msg, {
                title,
                variant:'danger'
            })
        },
        
        toastSuccess(title){
            const msg = "Tudo Certo"           
            this.$bvToast.toast(msg, {
                title,
                variant:'success'
            })            
        },
        parseValue(val) {
            
            const config = { style: "currency", currency: "BRL" };

            const numberFormat1 = new Intl.NumberFormat("pt-BR", config);

            return numberFormat1.format(val);
        },
        parseDate(date) {
            if (date) {
                return Moment(date).format("DD-MM-YYYY");
            } else {
                return "";
            }
        },     
        parseDateDatabase(date) {              
            if (date) {                
                return Moment(date, 'DD-MM-YYYY').format("YYYY-MM-DD");
            } else {
                return "";
            }
        },   
        parseDateDatabaseVueTheMask(date) {              
            if (date) {                
                return Moment(date, 'DDMMYYYY').format("YYYY-MM-DD");
            } else {
                return "";
            }
        },
        parseDateVueTheMask(date) {              
            if (date) {                
                return Moment(date, 'YYYY-MM-DD').format("DDMMYYYY");
            } else {
                return "";
            }
        },
        parseDateFull(date) {
            if (date) {
                return Moment(date).format("DD/MM/YYYY HH:MM:SS");
            } else {
                return "";
            }
        },
    }
}

Vue.mixin(mixin)