export default [
    {
      key: "id",
      label: "#",
      thStyle: {
        width: "5%",
        textAlign: "center",
      },
      thClass: "text-muted small",
      tdClass: "p-0 text-muted text-center pt-1",
    },
    {
      key: "contact_name",
      label: "Nome do Contato",
      thStyle: {
        width: "40%",
      },
      thClass: "text-muted small",
    },
    {
      key: "email",
      label: "Email do contato",
      thStyle: {
        width: "30%",
      },
      thClass: "text-muted small",
    },
    {
      key: "file.cell_phone",
      label: "Celular do contato",
      thStyle: {
        width: "20%",
      },
      thClass: "text-muted small",
    },
    {
      key: "status",
      label: "Status",
      thStyle: {
        width: "20%",
      },
      thClass: "text-muted small",
    },
    
  ]